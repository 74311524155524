import React from 'react';
import { Icon } from '@blueprintjs/core';
import truncateText from 'truncate';
import { getHost } from 'react-ftm/utils';

import './SeekSource.scss';

interface IURLProps {
  value: string;
}

const VIEWER_URL = 'https://seekpipe.seekuoia.info/source';

class SeekSource extends React.PureComponent<IURLProps> {
  render() {
    const { value, ...restProps } = this.props;
    if (!value) {
      return null;
    }
    const parts = value.split('|');
    let href = VIEWER_URL + '?tbl=' + parts[0] + '&offset=' + parts[1];
    if(parts.length == 3){
      href += '&limit=' + parts[2];
    }
    const label = getHost(value);
    const searchLink = '/search?q="' + value + '"';

    return (
      <span className="seekSource"><a
        {...restProps}
        href={href}
        className="URL"
        rel="noopener noreferrer"
        target="_blank"
        title={value}
      ><Icon icon="link" iconSize={14} />Entire row</a> / <a
        {...restProps}
        href={searchLink}
        className="URL"
        rel="noopener noreferrer"
        target="_blank"
        title={value}
      ><Icon icon="search" iconSize={14} />Sibling entities</a></span>
    );
  }
}

export default SeekSource;
